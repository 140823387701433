<template>
    <a-modal :visible="visible" title="项目详情" :footer="null" @cancel="cancel" width="1100px">

        <a-table bordered :dataSource="list" :pagination="false" :scroll="{ y: 400 }">

            <a-table-column title="序号" width="50px" align="center">
                <template slot-scope="text, row, index">
                    {{ index === list.length - 1 ? '合计' : index + 1 }}
                </template>
            </a-table-column>

            <a-table-column title="合同编号" data-index="code" />
            <a-table-column title="合同名称" data-index="name" width="400px" />

            <a-table-column title="完成进度" align="right" width="70px">
                <template slot-scope="text, row, index">
                    <span v-if="index === list.length - 1"></span>
                    <span v-else>
                        {{ typeof text.progress === 'number' ? text.progress + '%' : text.progress }}

                    </span>

                </template>
            </a-table-column>

            <a-table-column title="收入(元)" align="right">
                <template slot-scope="text">
                    <span style="color: #1890ff">
                        {{ text.income ? parseFloat(text.income).toLocaleString() : text.income }}
                    </span>
                </template>
            </a-table-column>
            <a-table-column title="成本(元)" align="right">
                <template slot-scope="text">
                    <span style="color: #1890ff">
                        {{ text.cost ? parseFloat(text.cost).toLocaleString() : text.cost }}
                    </span>
                </template>
            </a-table-column>
            <a-table-column title="利润(元)" align="right">
                <template slot-scope="text">
                    <span style="color: #1890ff">
                        {{ text.profit ? parseFloat(text.profit).toLocaleString() : text.profit }}
                    </span>
                </template>
            </a-table-column>

            <!-- <a-table-column align="center" title="操作" width="100px" fixed="right">
                <template slot-scope="text, record, index">
                    <a-space v-if="index !== list.length - 1">

                        <a href="#" v-if="$getPermission($route.path + '/edit')" @click.prevent="edit(text, index)">修改</a>
                        <a href="#" class="danger" v-if="$getPermission($route.path + '/delete')"
                            @click.prevent="deleteText(text, index)">删除</a>
                    </a-space>

                </template>
            </a-table-column> -->
        </a-table>


        <EditProject :visible="editVisible" :detail="selected" @cancel="cancelEdit" @refresh="refresh" />
    </a-modal>
</template>
  
<script>
import EditProject from './edit-project.vue';

import {
    removeProject
} from '../api'

export default {
    components: { EditProject },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        detail: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        list() {
            if (Array.isArray(this.detail.prjCheckList)) {
                const arr = [...this.detail.prjCheckList]
                let income = 0, cost = 0, profit = 0;
                arr.forEach(item => {
                    income += item.income;
                    cost += item.cost;
                    profit += item.profit;
                })
                arr.push({
                    income,
                    cost,
                    profit,
                })
                return arr

            } else {
                return []
            }
        }
    },
    data() {
        return {
            selected: {},
            editVisible: false,

            refreshTime: null,
        };
    },

    watch: {
        visible() {
            if (this.visible) {
                this.refreshTime = null;
            }
        }
    },
    methods: {
        cancel() {
            if (this.refreshTime) {
                this.$emit('refresh')
            }
            this.$emit("cancel");
        },

        deleteText(text, index) {
            const that = this;
            this.$confirm({
                title: "确认要删除吗？",
                onOk() {
                    removeProject({
                        id: text.id,
                    }).then(() => {
                        // that.getList();
                        that.refreshTime = Math.random();
                        if (Array.isArray(that.detail.prjCheckList)) {
                            that.detail.prjCheckList.splice(index, 1)
                        }
                    });
                },
            });
        },


        edit(text, index) {
            this.selectedIndex = index;
            this.selected = text;
            this.editVisible = true;
        },
        cancelEdit() {
            this.editVisible = false;
            this.selected = {}
        },

        refresh(item) {
            this.refreshTime = Math.random();
            if (Array.isArray(this.detail.prjCheckList)) {
                this.detail.prjCheckList.splice(this.selectedIndex, 1, item)
            }
        }
    },
};
</script>