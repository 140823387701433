var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"visible":_vm.visible,"title":"修改","footer":null},on:{"cancel":_vm.cancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 16 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"合同编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['code',
                    {
                        initialValue: _vm.detail.code,
                        rules: [{ required: true, message: '请输入！' }]
                    },
                ]),expression:"['code',\n                    {\n                        initialValue: detail.code,\n                        rules: [{ required: true, message: '请输入！' }]\n                    },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":true}})],1),_c('a-form-item',{attrs:{"label":"合同名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name',
                    {
                        initialValue: _vm.detail.name,
                        rules: [{ required: true, message: '请输入！' }]
                    },
                ]),expression:"['name',\n                    {\n                        initialValue: detail.name,\n                        rules: [{ required: true, message: '请输入！' }]\n                    },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":true}})],1),_c('a-form-item',{attrs:{"label":"进度"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['progress',
                    {
                        initialValue: _vm.detail.progress,
                        rules: [{ required: true, message: '请输入！' }]
                    },
                ]),expression:"['progress',\n                    {\n                        initialValue: detail.progress,\n                        rules: [{ required: true, message: '请输入！' }]\n                    },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"min":0,"max":100,"formatter":_vm.$ratioFormatter}})],1),_c('a-form-item',{attrs:{"label":"收入(元)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['income',
                    {
                        initialValue: _vm.detail.income,
                        rules: [{ required: true, message: '请输入！' }]
                    },
                ]),expression:"['income',\n                    {\n                        initialValue: detail.income,\n                        rules: [{ required: true, message: '请输入！' }]\n                    },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"formatter":_vm.$inputNumberFormat}})],1),_c('a-form-item',{attrs:{"label":"支出(元)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['cost',
                    {
                        initialValue: _vm.detail.cost,
                        rules: [{ required: true, message: '请输入！' }]
                    },
                ]),expression:"['cost',\n                    {\n                        initialValue: detail.cost,\n                        rules: [{ required: true, message: '请输入！' }]\n                    },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"formatter":_vm.$inputNumberFormat}})],1),_c('a-form-item',{attrs:{"label":"利润(元)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['profit',
                    {
                        initialValue: _vm.detail.profit,
                        rules: [{ required: true, message: '请输入！' }]
                    },
                ]),expression:"['profit',\n                    {\n                        initialValue: detail.profit,\n                        rules: [{ required: true, message: '请输入！' }]\n                    },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"formatter":_vm.$inputNumberFormat}})],1),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v("保存")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }